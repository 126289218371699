import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import Logo from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import Testimonials3 from '@solid-ui-blocks/Testimonials/Block03'
import GetStarted from '@solid-ui-blocks/CallToAction/Block02'
import Features2 from '@solid-ui-blocks/Features/Block05'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import MailchimpFormContainer from "../components/mailchimp-container";
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title="Scotland's leading education practice" lastmod="2022-09-12" />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['aboutUs']} />
      <ModalSimple content={content['teenagersInfo']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Logo content={content['logo']}/>
      <Hero content={content['hero']} />
      <Divider space='4' />
      <Divider space='4' />
      <Divider space='4' color='#3730a3' customWidth />
      <Divider space='4' />
      <Divider space='4' />
      <FeatureOne content={content['feature-one']} adjustMargin/>
      <Divider space='4' />
      <Features2 content={content['who-what-why']}></Features2>
      <Divider space='4' />
      <Container variant='full' sx={styles.testimonialsContainer}>
        <Testimonials3 content={content['caseStudies']} reverse/>
        <Divider space='5' />
      </Container>
      <Testimonials content={content['testimonials']}/>
      <Divider space='4' />
      <Container variant='full' sx={styles.testimonialsContainer2}>
        <GetStarted content={content['get-started']} />
        <MailchimpFormContainer/>
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageSiteBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/index", "shared"] } },
    ) {
      nodes {
        ...BlockContent,
      }
    }
  }
`
export default IndexPage
