/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import heroBg from './assets/contour-pattern.svg'

export default {
  heroContainer: {
    '::before': {
      content: `" "`,
      size: `full`,
      position: `absolute`,
      top: `-10%`,
      left: 0,
      zIndex: -1,
      background: t =>
        `url(${heroBg}) no-repeat center 0, linear-gradient(
          180deg,
          ${t.colors.alphaLighter} 0%,
          ${t.colors.background} 100%
        )`,
      backgroundSize: `100%`
    }
  },
  strategiesContainer: {
    position: `relative`,
    '::before': {
      content: `" "`,
      width: `full`,
      height: `150%`,
      position: `absolute`,
      top: 0,
      left: 0,
      zIndex: -1,
      background: t =>
        `url(${heroBg}) no-repeat center 0, linear-gradient(
          180deg,
          ${t.colors.alphaLighter} 0%,
          ${t.colors.background} 100%
        )`,
      backgroundSize: `100%`
    }
  },
  whyChooseUsContainer: {
    bg: `omegaLighter`,
    borderRadius: `xl`,
    py: 5,
    px: [4, 0]
  },
  testimonialsContainer: {
    position: `relative`,
    py: [5, 6],
    marginBottom: `-10%`,
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      width: [`full`, `90%`],
      height: `70%`,
      top: 30,
      right: `50%`,
      transform: `translate(50%, 0)`,
      zIndex: -1
    },
    '::before': {
      borderRadius: `xl`,
      background: t => `linear-gradient(
          150deg,
          ${t.colors.omegaLighter} 50%,
          ${t.colors.omegaLight} 100%
        )`
    },
  },
  testimonialsContainer2: {
    position: `relative`,
    py: [5, 6],
    marginBottom: `-10%`,
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      width: `90%`,
      height: `70%`,
      top: 30,
      right: `50%`,
      transform: `translate(50%, 0)`,
      zIndex: -1
    },
    '::before': {
      borderRadius: `xl`,
      background: t => t.colors.omegaLighter
    },
  }
}
