import React from 'react';

class EmailForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      submitted: false,
      returnMsg: ' ',
      status: props.status,
      message: props.message,
      onValidated: props.onValidated,
    };
  }

  render() {

    return (
      <div className="form">
        <form id="emailform">
          <p>Sign Up to Our Mailing List</p>
          <input
            type='text'
            name='email'
            placeholder='Email address'
            onChange={(newEmail) => {
              this.setState({ email: newEmail.target.value, returnMsg: ' ', submitted: false })
            }}
          />
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

              if (this.state.email === ' ' || !emailPattern.test(this.state.email)) {
                this.setState({ returnMsg: "Please enter a valid email address" });
              } else {
                this.state.email &&
                  this.state.email.indexOf("@") > -1 &&
                  this.props.onValidated({ EMAIL: this.state.email }); // calls subscribe() in container component
                this.setState({ submitted: true });
                document.getElementById("emailform").reset();
              }
            }}
          >
            <p>Submit</p>
          </button>
          <p>
            {this.state.returnMsg}
            {this.props.status === "sending" && this.state.submitted === true && (
              <div>
                Sending...
              </div>
            )}
            {this.props.status === "error" && this.state.submitted === true && (
              <div
                dangerouslySetInnerHTML={{ __html: this.props.message }}
              />
            )}
            {this.props.status === "success" && this.state.submitted === true && (
              <div
                dangerouslySetInnerHTML={{ __html: this.props.message }}
              />
            )}
          </p>
        </form>
      </div>
    )
  }
}

export default EmailForm;