import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import EmailForm from "./email-form";

const MailchimpFormContainer = props => {

  const postURL = `https://pcosbuddy.us10.list-manage.com/subscribe/post?u=${process.env.GATSBY_SUBSCRIBE_UID}&id=${process.env.GATSBY_SUBSCRIBE_ID}`;

  return (
    <div className='h-full'>
      <MailchimpSubscribe
        url={postURL}
        render={({ subscribe, status, message }) => (
          <EmailForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default MailchimpFormContainer;